import React, { useContext, useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';

import Home from '../../containers/Home/index';
import Categories from '../../containers/Categories';
import ComboOffers from '../../containers/ComboOffers';
import Cart from '../../containers/Cart';
import menuItems from '../menuItems';
import Search from '../../containers/Search';
import axios from 'axios';

import SearchContext from '../../Api/Context/searchContext';

const App = () => {
  // axios.defaults.baseURL = `http://127.0.0.1:8000`;
  // axios.defaults.baseURL = 'http://127.0.0.1:8000';
  // axios.defaults.baseURL = 'http://192.168.5.144:8000';
  axios.defaults.baseURL = 'https://hppl.hotelpauwa.com';
  return (
    <div>
      <main>
        <Switch>
          <Route exact path={`/`} component={Home} />
          <Route
            exact
            path='/categories/:company_name/:table_id'
            component={Categories}
          />
          <Route
            exact
            path='/subcategories/:subCatName'
            component={menuItems}
          />
          <Route exact path='/search/:keyword' component={Search} />
          <Route exact path='/combooffers/:id' component={ComboOffers} />
          <Route exact path='/cart' component={Cart} />
        </Switch>
      </main>
    </div>
  );
};

export default App;
